@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #1e293b; /* Equivalent to Tailwind's bg-gray-100 */
    min-height: 100vh;
    margin: 0;
  }
  
  select,
  option {
    font-family: 'Inter', sans-serif !important; /* Or your desired font */
    font-weight: 400; /* Adjust the font weight if needed */
    color: white !important; /* Force the text color */
    background-color: transparent !important; /* Adjust the background color */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

  }

  select:hover, option:hover {
    background-color: #14532d !important;
  }
  
  option {
    background-color: #1f2937 !important; /* Ensures options have the right background */
  }
  
  select:focus {
    outline: none;
  }
  
  